<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title class="font-weight-light text-h5" v-text="name" />

    <v-spacer />

    <!-- <default-search class="hidden-sm-and-down" /> -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn class="ml-3 mr-4" elevation="1" fab small v-on="on">
          <v-icon :color="currentGlobe">mdi-web</v-icon>
        </v-btn>
      </template>
      <span>{{
        currentGlobeId == 1
          ? "Mobile server working"
          : "Mobile server not working"
      }}</span>
    </v-tooltip>
    <default-go-home />

    <default-notifications />

    <default-account />
  </v-app-bar>
</template>

<script>
// Utilities
import { get, sync } from "vuex-pathify";

export default {
  name: "DefaultBar",

  components: {
    DefaultAccount: () =>
      import(
        /* webpackChunkName: "default-account" */
        "./widgets/Account"
      ),
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./widgets/DrawerToggle"
      ),
    DefaultGoHome: () =>
      import(
        /* webpackChunkName: "default-go-home" */
        "./widgets/GoHome"
      ),
    DefaultNotifications: () =>
      import(
        /* webpackChunkName: "default-notifications" */
        "./widgets/Notifications"
      ),
    // DefaultSearch: () =>
    // import(
    //   /* webpackChunkName: "default-search" */
    //   './widgets/Search'
    // ),
  },
  data: () => ({
    currentGlobe: "#f82328",
    greenGlobe: "#45e689",
    redGlobe: "#f82328",
    currentGlobeId: 0,
  }),
  computed: {
    ...sync("app", ["drawer", "mini"]),
    name: get("route/name"),
  },
  created() {
    // Listen to score changes coming from SignalR events
    this.$locationHub.$on(
      "signal-r-status-changed",
      this.onSignalRConnectionChanged
    );
  },
  beforeDestroy() {
    this.$locationHub.$off(
      "signal-r-status-changed",
      this.onSignalRConnectionChanged
    );
  },
  methods: {
    onSignalRConnectionChanged(isConnected) {
      if (isConnected) {
        this.currentGlobe = this.greenGlobe;
        this.currentGlobeId = 1;
      } else {
        this.currentGlobe = this.redGlobe;
        this.currentGlobeId = 0;
      }
    },
  },
};
</script>
